/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createUser = /* GraphQL */ `
  mutation CreateUser(
    $input: CreateUserInput!
    $condition: ModelUserConditionInput
  ) {
    createUser(input: $input, condition: $condition) {
      id
      name
      gender
      email
      messageCount
      voiceMessageCount
      isNewUser
      isPaidUser
      conversations {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      id
      name
      gender
      email
      messageCount
      voiceMessageCount
      isNewUser
      isPaidUser
      subscriptions
      conversations {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser(
    $input: DeleteUserInput!
    $condition: ModelUserConditionInput
  ) {
    deleteUser(input: $input, condition: $condition) {
      id
      name
      gender
      email
      messageCount
      voiceMessageCount
      isNewUser
      isPaidUser
      conversations {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const createCelebrity = /* GraphQL */ `
  mutation CreateCelebrity(
    $input: CreateCelebrityInput!
    $condition: ModelCelebrityConditionInput
  ) {
    createCelebrity(input: $input, condition: $condition) {
      id
      name
      image
      voiceId
      conversations {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateCelebrity = /* GraphQL */ `
  mutation UpdateCelebrity(
    $input: UpdateCelebrityInput!
    $condition: ModelCelebrityConditionInput
  ) {
    updateCelebrity(input: $input, condition: $condition) {
      id
      name
      image
      voiceId
      conversations {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteCelebrity = /* GraphQL */ `
  mutation DeleteCelebrity(
    $input: DeleteCelebrityInput!
    $condition: ModelCelebrityConditionInput
  ) {
    deleteCelebrity(input: $input, condition: $condition) {
      id
      name
      image
      voiceId
      conversations {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const createConversation = /* GraphQL */ `
  mutation CreateConversation(
    $input: CreateConversationInput!
    $condition: ModelConversationConditionInput
  ) {
    createConversation(input: $input, condition: $condition) {
      id
      userId
      user {
        id
        name
        gender
        email
        messageCount
        voiceMessageCount
        isNewUser
        isPaidUser
        createdAt
        updatedAt
        owner
        __typename
      }
      celebrityId
      celebrity {
        id
        name
        image
        voiceId
        createdAt
        updatedAt
        owner
        __typename
      }
      userAndCelebrity
      messages {
        nextToken
        __typename
      }
      lastMessage
      lastMessageTimestamp
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateConversation = /* GraphQL */ `
  mutation UpdateConversation(
    $input: UpdateConversationInput!
    $condition: ModelConversationConditionInput
  ) {
    updateConversation(input: $input, condition: $condition) {
      id
      userId
      user {
        id
        name
        gender
        email
        messageCount
        voiceMessageCount
        isNewUser
        isPaidUser
        createdAt
        updatedAt
        owner
        __typename
      }
      celebrityId
      celebrity {
        id
        name
        image
        voiceId
        createdAt
        updatedAt
        owner
        __typename
      }
      userAndCelebrity
      messages {
        nextToken
        __typename
      }
      lastMessage
      lastMessageTimestamp
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteConversation = /* GraphQL */ `
  mutation DeleteConversation(
    $input: DeleteConversationInput!
    $condition: ModelConversationConditionInput
  ) {
    deleteConversation(input: $input, condition: $condition) {
      id
      userId
      user {
        id
        name
        gender
        email
        messageCount
        voiceMessageCount
        isNewUser
        isPaidUser
        createdAt
        updatedAt
        owner
        __typename
      }
      celebrityId
      celebrity {
        id
        name
        image
        voiceId
        createdAt
        updatedAt
        owner
        __typename
      }
      userAndCelebrity
      messages {
        nextToken
        __typename
      }
      lastMessage
      lastMessageTimestamp
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const createMessage = /* GraphQL */ `
  mutation CreateMessage(
    $input: CreateMessageInput!
    $condition: ModelMessageConditionInput
  ) {
    createMessage(input: $input, condition: $condition) {
      id
      text
      isBotResponse
      isVoiceMessage
      timestamp
      conversation {
        id
        userId
        celebrityId
        userAndCelebrity
        lastMessage
        lastMessageTimestamp
        createdAt
        updatedAt
        owner
        __typename
      }
      conversationId
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateMessage = /* GraphQL */ `
  mutation UpdateMessage(
    $input: UpdateMessageInput!
    $condition: ModelMessageConditionInput
  ) {
    updateMessage(input: $input, condition: $condition) {
      id
      text
      isBotResponse
      isVoiceMessage
      timestamp
      conversation {
        id
        userId
        celebrityId
        userAndCelebrity
        lastMessage
        lastMessageTimestamp
        createdAt
        updatedAt
        owner
        __typename
      }
      conversationId
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteMessage = /* GraphQL */ `
  mutation DeleteMessage(
    $input: DeleteMessageInput!
    $condition: ModelMessageConditionInput
  ) {
    deleteMessage(input: $input, condition: $condition) {
      id
      text
      isBotResponse
      isVoiceMessage
      timestamp
      conversation {
        id
        userId
        celebrityId
        userAndCelebrity
        lastMessage
        lastMessageTimestamp
        createdAt
        updatedAt
        owner
        __typename
      }
      conversationId
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
