import React from 'react';
import { Paper, TextField, Button, Grid, IconButton, Alert, AppBar, Toolbar, Typography, MenuItem } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import axios from 'axios';
import '../App.css';
import TypingIndicator from "./TypingIndicator";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Auth, API, graphqlOperation } from 'aws-amplify';

import { createConversation, createMessage, updateConversation, updateUser } from '../graphql/mutations';
import { getUser, listConversations, listMessages } from '../graphql/queries';

class Chat_Chats extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
        userMessage: '',
        conversation: props.messages || [],
        showAlert: false,
        celebrity: "",
        isKeyboardOpen: false,
        currentUser: null,
        currentConversation: null
    };
    this.chatEndPoint = this.props.endpoint + "/run";
    this.messagesEndRef = React.createRef();
    this.inputRef = React.createRef();
  }

  handleCelebChange = (event) => {
    this.setState({
      celebrity: event.target.value
    });
  }

  // Further methods and component elements will come here.
  handleChange = (event) => {
    this.setState({
      userMessage: event.target.value
    });
  }
  
  submitMessage = async (event) => {
    event.preventDefault();

    this.inputRef.current.blur();
  
    if (this.state.userMessage.trim() === '') {
      // If the trimmed message is empty return early and do not process
      return;
    }

    const userInput = this.state.userMessage
    
    // The trimmed message is not empty, so you're good to add it to your state and send it
    this.setState({
        loading: true,
        conversation: [...this.state.conversation, { 'text': this.state.userMessage, 'isBotResponse': false }],
        userMessage: ''
    });

    setTimeout(() => {
      // After 15 seconds, if still loading, show the alert
      if (this.state.loading) {
        this.setState({showAlert: true});
      }
    }, 15000);

    if (this.state.conversation.length == 0) {
        await this.createNewConversation().then(() => {
        this.checkConversation().then(() => {
          this.createNewMessage(userInput).then(() => {
            this.updateConversationLastMessage(userInput)
          });
        })
      })} else {
        this.createNewMessage(userInput).then(() => {
          this.updateConversationLastMessage(userInput)
        });
      }
    
    axios.post(this.chatEndPoint, { 'prompt': this.state.userMessage, 'character_name': 'Mindy' })
      .then(response => {
      const newConversation = [...this.state.conversation, { 'text': response.data.output, 'isBotResponse': true }];
      this.setState(prevState => ({
        conversation: newConversation,
        loading: false,
        showAlert: false
      }));
      this.props.onChatUpdate(newConversation);
    }).catch(error => {
      console.error(`Error: ${error}`);
      this.setState({ loading: false });
    });    
  }

  checkConversation = async () => {
    try {
      this.currentUser = await Auth.currentAuthenticatedUser();
  
      const response = await API.graphql(graphqlOperation(listConversations));
      const allConversations = response.data.listConversations.items;
  
      const userAndCelebrity = `${this.currentUser.attributes.sub}_${this.props.selectedConversation.celebrity.id}`;
  
      // Filter the required conversation 
      this.currentConversation = allConversations.find(conversation => conversation.userAndCelebrity === userAndCelebrity);
      console.log(this.currentConversation)
    } catch (error) {
      console.log(error)
    }
  }

  listConversationMessages = async () => {
    try {
      const messagesData = await API.graphql(graphqlOperation(listMessages, { filter: { conversationId: { eq: this.currentConversation.id }}}));
      let messages = messagesData.data.listMessages.items.map((message) => {
        return {
          id: message.id,
          text: message.text,
          isBotResponse: message.isBotResponse,
          createdAt: message.createdAt  //Assuming your messages have a createdAt timestamp
        }
      });

      console.log('Messages:', messages);

      // sort the messages by createdAt
      messages.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));

      let formattedMessages = messages.map(message => {
        return {
          text: message.text,
          isBotResponse: message.isBotResponse // Negate isUserMessage to get isBotResponse
        }
      });
  
      this.setState({ conversation: formattedMessages });
      
    } catch {
      console.log('No current messages')
    }
  }

  updateMessageCount = async () => {
    try {
      const userData = await API.graphql(graphqlOperation(getUser, {
        id: this.currentUser.attributes.sub
      }))

      const messageCount = userData.data.getUser.messageCount;
      
      const update = await API.graphql(graphqlOperation(updateUser, {
        input: {
          id: this.currentUser.attributes.sub,
          messageCount: messageCount + 1
        }
      }))
      

      console.log(update)
    } catch (error) {
      console.log(error)
    }
  }

  createNewMessage = async (text) => {
    try {
      //const currentUser = await Auth.currentAuthenticatedUser();
  
      const response = await API.graphql(graphqlOperation(listConversations));
      const allConversations = response.data.listConversations.items;
  
      const userAndCelebrity = `${this.currentUser.attributes.sub}_${this.props.selectedConversation.celebrity.id}`;
  
      // Filter the required conversation 
      //const currentConversation = allConversations.find(conversation => conversation.userAndCelebrity === userAndCelebrity);
  
      if (this.currentConversation) {
        const result = await API.graphql(graphqlOperation(createMessage, {
          input: {
            text: text,
            isBotResponse: false,
            timestamp: new Date().toISOString(),
            conversationId: this.currentConversation.id, // Work with the ID of the conversation
          }
        }));

        this.updateMessageCount()
  
        console.log('Created a message:', result);
      } else {
        console.error('No conversation found with the given user and celebrity');
      }
    } catch (error) {
      console.log('Error creating new message:', error);
    }
  };

  componentDidUpdate() {
   //this.scrollToBottom();
  }

  scrollToBottom = () => {
    //this.messagesEndRef.current.scrollIntoView({ behavior: 'smooth' })
  }

  updateConversationLastMessage = async (lastMessage) => {
    try {
      const update = await API.graphql(graphqlOperation(updateConversation, {
        input: {
          id: this.currentConversation.id,
          lastMessage: lastMessage
        }
      }))
      console.log(update)
    } catch (error) {
      console.log('no current last message')
    }
  }

  updateHeight = () => {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  }

  async componentDidMount() {
    this.updateHeight();
    console.log(this.conversation);
    await this.checkConversation();
    this.listConversationMessages();
    //window.addEventListener('resize', this.updateHeight);
    //this.scrollToBottom();
  }

  componentWillUnmount() {
    //window.removeEventListener('resize', this.updateHeight);
  }

  render() {
    //let imageRatio = 480 / 640; // width / height
    //let vh = window.innerHeight * 0.01;
    //let vw = window.innerWidth * 0.01;
    //let elementHeight = vh * 100; // chat component wants to take up 100vh
    //let elementWidth = elementHeight * imageRatio;
    // "https://pbxt.replicate.delivery/ZXIfokdzxEWiQyiReaBRqT8tmTF4FxkNd8cu2PejBHNVeMMFB/out-0.png"
    // "https://i.postimg.cc/wM3wQqCS/Pers-04-p10.jpg"
    let backgroundImageUrl = "https://pbxt.replicate.delivery/ZXIfokdzxEWiQyiReaBRqT8tmTF4FxkNd8cu2PejBHNVeMMFB/out-0.png"
    const imageRatio = 480 / 640;  // width / height, adjust according to your image's ratio

    let vw = window.innerWidth * 0.01;
    let vh = window.innerHeight * 0.01;
    
    let viewportHeight = `calc(var(--vh, ${vh}px) * 100)`;
    let viewportWidth = `${vw * 100}px`;
    
    let elementHeight = '100%'
    let elementWidth = `min(calc(${viewportHeight} * ${imageRatio}), ${viewportWidth})`;

    if (elementWidth > window.innerWidth) {
      elementHeight = vh * 100; // chat component wants to take up 100vh
      elementWidth = vw * 100
      console.log(window.innerWidth)
      console.log(elementWidth)
    }
        
    const aspectRatio = 640 / 480;  // height / width -> adjust according to your image's ratio

    const celebrities = ['Mindy', 'Bob', 'Alice'];
    
    let style = {
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        padding: '0%',
        height: elementHeight,
        width: elementWidth,
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        backgroundColor: 'white',
        overflow: 'hidden',
        paddingTop: '115px'
    }
      
      return (
        <React.Fragment>
        <AppBar position="absolute" style={{ top: '55px', left: '50%', transform: "translateX(-50%)", boxShadow: '0px 3px 3px 0px rgba(0, 0, 0, 0.2)', width: `${elementWidth}`, backgroundColor: 'white'}}>
          <Toolbar>
            <IconButton edge="start" color="black" aria-label="back" onClick={() => this.props.onChatClose()}>
              <ArrowBackIcon />
            </IconButton>
            <img src={this.props.selectedConversation.celebrity.image} style={{ borderRadius: "50%", objectFit: 'cover', height: '45px', width: '45px', marginRight: '7px'}} />
            <Typography variant="h6" color="black">
              {this.props.selectedConversation.celebrity.name}
            </Typography>
          </Toolbar>
        </AppBar>
        {this.state.showAlert && <Alert severity="warning" style={{ position: 'absolute', top: 0, left: 0, right: 0, margin: 'auto', zIndex: 2, maxWidth: elementWidth }}>Your character is loading. <strong>This may take a while.</strong></Alert>}
        <Paper square id='chat-box' style={style} elevation={0}>
        <Grid container style={{ overflow: 'auto', flex: 0.9, marginTop: '20px' }}>
          <div style={{ overflowY: 'auto', minWidth: '100%' }}>
              {this.state.conversation.map((message, index) =>
              <div key={index} style={{ textAlign: message.isBotResponse ? 'left' : 'right', paddingRight: '20px', paddingLeft: '20px' }} ref={index === (this.state.conversation.length - 1) ? this.messagesEndRef : null}>
                  <div style={{
                  backgroundColor: message.isBotResponse ? '#bbdefb' : '#ffcdd2',
                  color: 'black',
                  borderRadius: '20px',
                  padding: '10px',
                  marginBottom: '10px',
                  display: 'inline-block',
                  maxWidth: '70%',
                  opacity: '75%'
                  }}>
                  {message.text}
                  </div>
              </div>
          
              )}
              { this.state.loading && <TypingIndicator /> }
              <div ref={this.messagesEndRef} />
          </div>
        </Grid>
            
        <form onSubmit={this.submitMessage} style={{position: 'sticky', bottom: 0, padding: 10, background: '#fff'}}>
          <TextField 
              inputRef={this.inputRef}
              fullWidth
              variant="filled"
              label="Message"
              value={this.state.userMessage}
              onChange={this.handleChange}
              InputProps={{
                  disableUnderline: true,
                  endAdornment: (
                      <IconButton color="default" type="submit">
                          <SendIcon style={{color: 'lightgray'}}/>
                      </IconButton>
                  ),
                  style: {
                      color: 'white',
                      borderRadius: '20px',
                      backdropFilter: 'blur(10px)',
                      backgroundColor: 'rgba(0, 0, 0, 0.4)',
                      minWidth: '100%'
                  },
              }}
              InputLabelProps={{
                  style: {
                      color: 'lightgray'
                  }
              }}
              style={{
                  borderRadius: '20px',
                  marginBottom: '0px'
              }}
          />
        </form>
        </Paper>
        </React.Fragment>
      );
    }
  }
  
  export default Chat_Chats;