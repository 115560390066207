import { Auth, API, graphqlOperation } from 'aws-amplify';
import { useEffect, useState } from 'react';
import { Grid, Card, CardContent, CardMedia, Typography, IconButton, Paper, Box } from '@mui/material';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import PlaceholderImage from '../placeholder.jpg';
import { listCelebrities } from '../graphql/queries';

import mixpanel from 'mixpanel-browser';

import Chat from './Chat.js'

function Home({ celebrities, setMessageLimitReached, endpoint }) {

    const [selectedCelebrity, setSelectedCelebrity] = useState(null);

    let backgroundImageUrl = "https://pbxt.replicate.delivery/ZXIfokdzxEWiQyiReaBRqT8tmTF4FxkNd8cu2PejBHNVeMMFB/out-0.png"
    const imageRatio = 480 / 640;  // width / height, adjust according to your image's ratio

    let vw = window.innerWidth * 0.01;
    let vh = window.innerHeight * 0.01;
    
    let viewportHeight = `calc(var(--vh, ${vh}px) * 100)`;
    let viewportWidth = `${vw * 100}px`;
    
    let elementHeight = viewportHeight;
    let elementWidth = `min(calc(${viewportHeight} * ${imageRatio}), ${viewportWidth})`;

    if (elementWidth > window.innerWidth) {
      elementHeight = vh * 100; // chat component wants to take up 100vh
      elementWidth = vw * 100
    }

    let style = {
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        padding: '0%',
        height: elementHeight,
        width: elementWidth,
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        backgroundColor: 'white',
        overflow: 'hidden',
        paddingTop: '70px'
    }
    
    //{ name: "Kylie Jenner", image: "https://assets.teenvogue.com/photos/64c2709317534afc665b9283/1:1/pass/undefined" }
    
    useEffect(() => {
        mixpanel.track('Page Viewed', { page: 'Home' });
    }, []);

    const handleCelebrityClick = (celebrity) => {
        setSelectedCelebrity(celebrity);
        console.log("Card clicked: ", celebrity);
    }

    const handleChatClose = () => {
        setSelectedCelebrity(null);
        console.log("Back")
    };

    if (selectedCelebrity) {
        return <Chat selectedCelebrity={selectedCelebrity} endpoint={endpoint} setMessageLimitReached={setMessageLimitReached} onChatClose={handleChatClose}/>
    }

    return (
        <Paper square id='home-box' style={style} elevation={0}>
            <Box sx={(theme) => ({ marginLeft: '-10px', marginRight: '-10px', [theme.breakpoints.down('sm')]: {marginLeft: theme.spacing(1), marginRight: theme.spacing(1) },})}>
            <Typography variant="h5" marginLeft='10px' fontWeight='600' gutterBottom>Discover</Typography>
            <Box style={{ maxHeight: '80vh', overflow: 'auto' }}> 
                <Grid container>
                {celebrities.map((celebrity, index) => (
                    <Grid item xs={6} sm={6} key={index}>
                    <Box style={{ margin: '10px', boxShadow: '0px 3px 5px 0px rgba(0, 0, 0, 0.2)', border: 'solid',
                                borderRadius: '5px',
                                borderWidth: '1px',
                                borderColor: 'gray' }}> {/* Add margin to each Box wrapping a Card */}
                        <Card onClick={() => handleCelebrityClick(celebrity)}>
                        <CardMedia
                            component="img"
                            height="250"
                            image={celebrity.image}
                            alt="placeholder"
                        />
                        <Box 
                            component={CardContent} 
                            sx={{ 
                                display: 'flex', 
                                flexDirection: 'row', 
                                justifyContent: 'space-between', 
                                height: '100%',
                                alignItems: 'center'
                            }}
                            >
                            <Typography 
                                variant="h6" 
                                component="div"
                            >
                                {celebrity.name}
                            </Typography>
                            <IconButton 
                                color="black"
                                aria-label="move to detail"
                                component="span"
                            >
                                <ArrowForwardIosIcon />
                            </IconButton>
                        </Box>
                        </Card>
                    </Box>
                    </Grid>
                ))}
                </Grid>
            </Box>
            </Box>
        </Paper>
    );
}

export default Home;