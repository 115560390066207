import React from "react";
import "./TypingIndicator.css";

export default function TypingIndicator() {
  return (
        <div className='typingIndicator' style={{
            backgroundColor: '#bbdefb',
            color: 'black',
            borderRadius: '20px',
            padding: '10px',
            marginBottom: '10px',
            display: 'inline-block',
            maxWidth: '70%',
            marginLeft: '20px',
            opacity: '75%'
            }}>
            <span></span>
            <span></span>
            <span></span>
        </div>
  )
}