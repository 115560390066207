/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      email
      name
      gender
      messageCount
      voiceMessageCount
      isNewUser
      isPaidUser
      subscriptions
      conversations {
        items {
          id
          userId
          celebrityId
          lastMessage
          lastMessageTimestamp
          messages {
            items {
              id
              text
              isBotResponse
              timestamp
            }
          }
          user {
            id
            email
          }
          celebrity {
            id
            name
            image
          }
        }
        nextToken
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        gender
        email
        messageCount
        voiceMessageCount
        isNewUser
        isPaidUser
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getCelebrity = /* GraphQL */ `
  query GetCelebrity($id: ID!) {
    getCelebrity(id: $id) {
      id
      name
      image
      voiceId
      conversations {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listCelebrities = /* GraphQL */ `
  query ListCelebrities(
    $filter: ModelCelebrityFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCelebrities(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        image
        voiceId
        stripe
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getConversation = /* GraphQL */ `
  query GetConversation($id: ID!) {
    getConversation(id: $id) {
      id
      userId
      user {
        id
        name
        gender
        email
        messageCount
        voiceMessageCount
        isNewUser
        isPaidUser
        createdAt
        updatedAt
        owner
        __typename
      }
      celebrityId
      celebrity {
        id
        name
        image
        voiceId
        createdAt
        updatedAt
        owner
        __typename
      }
      userAndCelebrity
      messages {
        nextToken
        __typename
      }
      lastMessage
      lastMessageTimestamp
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listConversations = /* GraphQL */ `
  query ListConversations(
    $filter: ModelConversationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listConversations(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userId
        celebrityId
        userAndCelebrity
        lastMessage
        lastMessageTimestamp
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const conversationsByUserId = /* GraphQL */ `
  query ConversationsByUserId(
    $userId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelConversationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    conversationsByUserId(
      userId: $userId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        celebrityId
        userAndCelebrity
        lastMessage
        lastMessageTimestamp
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const conversationsByCelebrityId = /* GraphQL */ `
  query ConversationsByCelebrityId(
    $celebrityId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelConversationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    conversationsByCelebrityId(
      celebrityId: $celebrityId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        celebrityId
        userAndCelebrity
        lastMessage
        lastMessageTimestamp
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const conversationsByUserAndCelebrity = /* GraphQL */ `
  query ConversationsByUserAndCelebrity(
    $userAndCelebrity: String!
    $sortDirection: ModelSortDirection
    $filter: ModelConversationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    conversationsByUserAndCelebrity(
      userAndCelebrity: $userAndCelebrity
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        celebrityId
        userAndCelebrity
        lastMessage
        lastMessageTimestamp
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getMessage = /* GraphQL */ `
  query GetMessage($id: ID!) {
    getMessage(id: $id) {
      id
      text
      isBotResponse
      isVoiceMessage
      timestamp
      conversation {
        id
        userId
        celebrityId
        userAndCelebrity
        lastMessage
        lastMessageTimestamp
        createdAt
        updatedAt
        owner
        __typename
      }
      conversationId
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listMessages = /* GraphQL */ `
  query ListMessages(
    $filter: ModelMessageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMessages(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        text
        isBotResponse
        isVoiceMessage
        timestamp
        conversationId
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const messagesByConversationId = /* GraphQL */ `
  query MessagesByConversationId(
    $conversationId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelMessageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    messagesByConversationId(
      conversationId: $conversationId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        text
        isBotResponse
        isVoiceMessage
        timestamp
        conversationId
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
