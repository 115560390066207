import { Auth, API, graphqlOperation } from 'aws-amplify';
import { Grid, Button, Box, Typography, Paper, CardContent } from '@mui/material';
import { useState, useEffect } from 'react';
import { getUser } from '../graphql/queries.js';

import Chat_Chats from './Chat_Chats.js'

const Chats = ({ endpoint}) => {
  const [selectedConversation, setSelectedConversation] = useState(null);
  const [conversations, setConversations] = useState([]);
  const [conversation, setConversation] = useState([]);

  const celebrities = [
    { name: "Pokimane", image: "https://s.yimg.com/uu/api/res/1.2/tVPtLaZ75SmfVaJu45qBHg--~B/Zmk9ZmlsbDtoPTU4Mzt3PTg3NTthcHBpZD15dGFjaHlvbg--/https://media-mbst-pub-ue1.s3.amazonaws.com/creatr-uploaded-images/2021-10/b623e8c0-372f-11ec-b7f3-a5b9045875bc.cf.jpg" },
    { name: "Kylie Jenner", image: "https://assets.teenvogue.com/photos/64c2709317534afc665b9283/1:1/pass/undefined" }
  ]

  const imageRatio = 480 / 640;  // width / height, adjust according to your image's ratio

    let vw = window.innerWidth * 0.01;
    let vh = window.innerHeight * 0.01;
    
    let viewportHeight = `calc(var(--vh, ${vh}px) * 100)`;
    let viewportWidth = `${vw * 100}px`;
    
    let elementHeight = viewportHeight;
    let elementWidth = `min(calc(${viewportHeight} * ${imageRatio}), ${viewportWidth})`;

    if (elementWidth > window.innerWidth) {
      elementHeight = vh * 100; // chat component wants to take up 100vh
      elementWidth = vw * 100
    }

  let style = {
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    padding: '0%',
    height: elementHeight,
    width: elementWidth,
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: 'white',
    overflow: 'hidden',
    paddingTop: '70px'
}

useEffect(() => {
    const fetchUserConversations = async () => {
      try {
        const userData = await Auth.currentAuthenticatedUser();
        const userConversationsData = await API.graphql(graphqlOperation(getUser, { id: userData.attributes.sub }));
        const userConversations = userConversationsData.data.getUser.conversations.items;
        console.log(userConversationsData)
        setConversations(userConversations.sort((a, b) => new Date(b.lastMessageTimestamp) - new Date(a.lastMessageTimestamp)));
        console.log('fetched conversations', userConversations)
        console.log('fetched conversations', userConversationsData.data.getUser.conversations)
      } catch (error) {
        console.log("error fetching conversations", error);
      }
    };

    fetchUserConversations();
}, []);

const handleConversationClick = (conversation) => {
    setSelectedConversation(conversation);
    console.log("Conversation clicked: ", conversation);
}

const handleChatClose = () => {
    setSelectedConversation(null);
    console.log("Back")
};
  
if (selectedConversation) {
    return <Chat_Chats selectedConversation={selectedConversation} onChatClose={handleChatClose} />
}

  return (
    <Paper square id='home-box' style={style} elevation={0}>
            <Box sx={(theme) => ({ marginLeft: '-10px', marginRight: '-10px', [theme.breakpoints.down('sm')]: {marginLeft: theme.spacing(1), marginRight: theme.spacing(1) },})}>
            <Typography variant="h5" marginLeft='10px' fontWeight='600' gutterBottom>Chats</Typography>
            <Box style={{ maxHeight: '80vh', overflow: 'auto' }}> 
                <Grid container>
                {conversations.map((conversation, index) => (
                    <Grid item xs={12} sm={12} key={index}>
                    <Box style={{ margin: '10px' }}> {/* Add margin to each Box wrapping a Card */}
                        <Box 
                            onClick={() => handleConversationClick(conversation)}
                            component={CardContent} 
                            sx={{ 
                                display: 'flex', 
                                flexDirection: 'row', 
                                justifyContent: 'left', 
                                height: '100%',
                                alignItems: 'center',
                                boxShadow: '0px 3px 5px 0px rgba(0, 0, 0, 0.2)',
                                border: 'solid',
                                borderRadius: '5px',
                                borderWidth: '1px',
                                borderColor: 'gray'
                            }}
                            >
                            <img src={conversation.celebrity.image} style={{ borderRadius: "50%", objectFit: 'cover', height: '45px', width: '45px', marginRight: '10px'}} />
                            <Box sx={{ display: 'flex', flexDirection: 'column'}}>
                            <Typography 
                                variant="h6" 
                                component="div"
                            >
                                {conversation.celebrity.name}
                            </Typography>
                            <Typography 
                                variant="p" 
                                component="div"
                                color="gray"
                            >
                                {conversation.lastMessage}
                            </Typography>
                            </Box>
                        </Box>
                    </Box>
                    </Grid>
                ))}
                </Grid>
            </Box>
            </Box>
        </Paper>
  );
};

export default Chats;

