import React from 'react';
import { Box, Paper, TextField, Button, Grid, IconButton, Alert, AppBar, Toolbar, Typography, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Avatar } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import axios from 'axios';
import '../App.css';
import TypingIndicator from "./TypingIndicator";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CloseIcon from "@mui/icons-material/Close";
import { loadStripe } from '@stripe/stripe-js';
import { Auth, API, graphqlOperation } from 'aws-amplify';

import { createConversation, createMessage, updateConversation, updateUser } from '../graphql/mutations';
import { getUser, listConversations, listMessages } from '../graphql/queries';

import mixpanel from 'mixpanel-browser';

class Chat extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
        userMessage: '',
        conversation: props.messages || [],
        showAlert: false,
        celebrity: "",
        isKeyboardOpen: false,
        currentUser: null,
        currentConversation: null,
        messageLimitReached: false,
        voiceMessageLimitReached: false,
        prevConversationLength: 0,
        dialogOpen: false,
        stripe: null,
        isStripeLoading: true,
        validSubscription: null,
        pricingData: null
    };
    this.runEndPoint = this.props.endpoint + "/run";
    this.messagesEndRef = React.createRef();
    this.inputRef = React.createRef();
  }

  handleCelebChange = (event) => {
    this.setState({
      celebrity: event.target.value
    });
  }

  // Further methods and component elements will come here.
  handleChange = (event) => {
    this.setState({
      userMessage: event.target.value
    });
  }
  
  submitMessage = async (event) => {
    event.preventDefault();

    console.log(this.props.endpoint)

    this.inputRef.current.blur();
  
    if (this.state.userMessage.trim() === '') {
      // If the trimmed message is empty return early and do not process
      return;
    }

    const userInput = this.state.userMessage
    
    // The trimmed message is not empty, so you're good to add it to your state and send it
    this.setState({
        loading: true,
        conversation: [...this.state.conversation, { 'text': this.state.userMessage, 'isBotResponse': false, 'isVoiceMessage': false }],
        userMessage: ''
    });

    setTimeout(() => {
      // After 15 seconds, if still loading, show the alert
      if (this.state.loading) {
        this.setState({showAlert: true});
      }
    }, 15000);

    console.log(this.state.userMessage)

    if (this.state.conversation.length == 0) {
      await this.createNewConversation().then(() => {
      this.checkConversation().then(() => {
        this.createNewMessage(userInput).then(() => {
          this.updateConversationLastMessage(userInput)
        });
      })
    })} else {
      this.createNewMessage(userInput).then(() => {
        this.updateConversationLastMessage(userInput)
      });
    }
    
    /*
    axios.post(this.chatEndPoint, { 'user_input': this.state.userMessage, 'character_name': 'Mindy', 'user_id': this.currentUser.attributes.sub, 'conversation_id': this.currentConversation.id, 'voice_id': this.props.selectedCelebrity.voice_id })
      .then(response => {
      const newConversation = [...this.state.conversation, { 'text': response.data.output, 'isBotResponse': true }];
      this.setState(prevState => ({
        conversation: newConversation,
        loading: false,
        showAlert: false
      }));
      this.props.onChatUpdate(newConversation);
    }).catch(error => {
      console.error(`Error: ${error}`);
      this.setState({ loading: false });
    });    
    */
  }

  checkConversation = async () => {
    try {
      this.currentUser = await Auth.currentAuthenticatedUser();
  
      const response = await API.graphql(graphqlOperation(listConversations));
      const allConversations = response.data.listConversations.items;
  
      const userAndCelebrity = `${this.currentUser.attributes.sub}_${this.props.selectedCelebrity.id}`;
  
      // Filter the required conversation 
      this.currentConversation = allConversations.find(conversation => conversation.userAndCelebrity === userAndCelebrity);
      console.log(this.currentConversation)
    } catch (error) {
      console.log(error)
    }
  }

  listConversationMessages = async () => {
    try {
      const messagesData = await API.graphql(graphqlOperation(listMessages, { filter: { conversationId: { eq: this.currentConversation.id }}}));
      let messages = messagesData.data.listMessages.items.map((message) => {
        return {
          id: message.id,
          text: message.text,
          isBotResponse: message.isBotResponse,
          isVoiceMessage: message.isVoiceMessage,
          createdAt: message.createdAt  //Assuming your messages have a createdAt timestamp
        }
      });

      console.log('Messages:', messages);

      // sort the messages by createdAt
      messages.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));

      let formattedMessages = messages.map(message => {
        return {
          text: message.text,
          isBotResponse: message.isBotResponse // Negate isUserMessage to get isBotResponse
        }
      });
  
      this.setState({ conversation: formattedMessages });
      
    } catch {
      console.log('No current messages')
    }
  }

  updateConversationLastMessage = async (lastMessage) => {
    try {
      const update = await API.graphql(graphqlOperation(updateConversation, {
        input: {
          id: this.currentConversation.id,
          lastMessage: lastMessage
        }
      }))
      console.log(update)
    } catch (error) {
      console.log('no current last message')
    }
  }

  updateHeight = () => {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  }

  updateMessageCount = async (isVoiceMessage) => {
    try {
      const userData = await API.graphql(graphqlOperation(getUser, {
        id: this.currentUser.attributes.sub
      }))
      
      const messageCount = userData.data.getUser.messageCount;
      const voiceMessageCount = userData.data.getUser.voiceMessageCount
      
      if (isVoiceMessage) {
        const update = await API.graphql(graphqlOperation(updateUser, {
          input: {
            id: this.currentUser.attributes.sub,
            voiceMessageCount: voiceMessageCount + 1
          }
        }))
      } else {
        const update = await API.graphql(graphqlOperation(updateUser, {
          input: {
            id: this.currentUser.attributes.sub,
            messageCount: messageCount + 1
          }
        }))
      }

      console.log(messageCount)
      

      this.setState({
        prevConversationLength: this.state.prevConversationLength + 1
      });
      
      if (messageCount >= 100 & userData.data.getUser.isPaidUser == false) {
        this.setState({
          messageLimitReached: true
        });
        this.props.setMessageLimitReached(true)
        console.log(messageCount)
        mixpanel.track('Popup Viewed', { popup: 'Message Limit' });
      }

      if (voiceMessageCount >= 3 & userData.data.getUser.isPaidUser == false) {
        this.setState({
          voiceMessageLimitReached: true
        });
        //this.props.setMessageLimitReached(true)
        console.log(voiceMessageCount)
        mixpanel.track('Popup Viewed', { popup: 'Voice Message Limit' });
      }

    } catch (error) {
      console.log(error)
    }
  }

  async componentDidMount() {
    this.updateHeight();
    this.fetchStripePricing(this.props.selectedCelebrity.stripe)
    console.log(this.conversation);
    await this.checkConversation();
    this.listConversationMessages();
    const userData = await API.graphql(graphqlOperation(getUser, {
      id: this.currentUser.attributes.sub
    }))
    const messageCount = userData.data.getUser.messageCount;
    const voiceMessageCount = userData.data.getUser.voiceMessageCount
    const subscriptions = userData.data.getUser.subscriptions
    console.log(subscriptions)
    if (subscriptions.includes(this.props.selectedCelebrity.name)) {
      this.setState({
        validSubscription: true
      });
    } else {
      this.setState({
        validSubscription: false
      });
    }
    if (voiceMessageCount >= 3 & userData.data.getUser.isPaidUser == false) {
      this.setState({
        voiceMessageLimitReached: true
      });
      //this.props.setMessageLimitReached(true)
      console.log(voiceMessageCount)
    } else if (messageCount >= 100 & userData.data.getUser.isPaidUser == false) {
      this.setState({
        messageLimitReached: true
      });
      this.props.setMessageLimitReached(true)
      console.log(messageCount)
    }
    //window.addEventListener('resize', this.updateHeight);
    //this.scrollToBottom();
    const stripe = await loadStripe('pk_live_51NegAUAbGNQ8WGGF7GLC5IYuDMz2ACDufF8V9rnQ3w9X1QKmXNjIWNsb8RKXjNtvw7PBN7onUWI03kPSsvKILXz60095eznxVR');
    this.setState({ stripe, isStripeLoading: false });
  }

  scrollToBottom = () => {
    this.messagesEndRef.current.scrollIntoView({ behavior: 'smooth' })
  }

  componentDidUpdate() {
    if (this.state.prevConversationLength !== this.state.conversation.length) {
      //this.scrollToBottom();
    }
  }

  componentWillUnmount() {
    //window.removeEventListener('resize', this.updateHeight);
  }

  openPaymentLink = () => {
    /*
    window.open("https://buy.stripe.com/4gwaGIcggemj3966oo", "_blank")

    const stripeAccountId = 'acct_1NegAUAbGNQ8WGGF'; // Replace with your Stripe account ID

    // Assuming you have the user's email available in your component state or props
    const userId = this.currentUser.attributes.sub; // Replace with your way of getting the user's email

    const metadata = {
      userId: userId,
    };

    const metadataString = encodeURIComponent(JSON.stringify(metadata));

    // Construct the payment link with the discount code
    const couponId = 'FLASHSALE';
    const paymentLink = `https://buy.stripe.com/4gwaGIcggemj3966oo?redirect=https://joyful-valkyrie-55415f.netlify.app/&account=${stripeAccountId}&key=key123#lang=en&metadata=${metadataString}&discount=${couponId}`;

    window.open(paymentLink, '_blank');
    

    const stripe = loadStripe('pk_live_51NegAUAbGNQ8WGGF7GLC5IYuDMz2ACDufF8V9rnQ3w9X1QKmXNjIWNsb8RKXjNtvw7PBN7onUWI03kPSsvKILXz60095eznxVR');

    const userId = this.currentUser.attributes.sub; // Replace with your way of getting the user's email

    const metadata = {
      userId: userId,
    };

    const items = [
        { price: 'prctbl_1OLS5OAbGNQ8WGGFOpNlLGkt', quantity: 1 } // Replace 'your_subscription_price_id' with the specific Price ID
    ];

    // Create a Checkout Session with the promo code applied
    stripe.redirectToCheckout({
        lineItems: items,
        mode: 'subscription', // Ensure the mode is 'subscription' for subscription products
        payment_method_types: ['card'],
        successUrl: 'https://example.com/success',
        cancelUrl: 'https://example.com/cancel',
        promotionCode: 'FLASHSALE', // Automatically apply the FLASHSALE promo code
        metadata: metadata
    }).then(function(result) {
        if (result.error) {
            console.error(result.error.message);
        }
    });
    */
  }

  initiateStripeCheckout = async () => {

    try {

      const { stripe } = this.state;

      if (!stripe) {
        console.error('Stripe has not been initialized yet.');
        return;
      }

      const userId = this.currentUser.attributes.sub; // Replace with your way of getting the user's email

      mixpanel.track('Open Checkout', {
        userId: userId
      });

      const { error } = await stripe.redirectToCheckout({
        lineItems: [
          { price: this.props.selectedCelebrity.stripe, quantity: 1 }
        ],
        mode: 'subscription', // or 'subscription' if you're creating a subscription
        successUrl: 'https://app.ploki.fun',
        cancelUrl: 'https://app.ploki.fun',
        clientReferenceId: `${userId}_${this.props.selectedCelebrity.name}`
      });
  
      if (error) {
        console.error('Error:', error);
      }

    } catch (error) {
      console.error('Error initiating Stripe Checkout:', error);
    }
  };

  createNewConversation = async () => {
    try {
      //const currentUser = await Auth.currentAuthenticatedUser();
      console.log('userID ', this.currentUser.attributes.sub);
      const result = await API.graphql(graphqlOperation(createConversation, {
        input: {
 // Represents the Conversation ID, needs to be unique 
          userId: this.currentUser.attributes.sub, // Obtains id from current logged-in Cognito user
          celebrityId: this.props.selectedCelebrity.id, // Represents the Celebrity ID, replace with your Celebrity ID
          userAndCelebrity: this.currentUser.attributes.sub + '_' + this.props.selectedCelebrity.id,
          lastMessage: 'Initial Message Text',
          lastMessageTimestamp: new Date().toISOString()
        }
      }));
      console.log('Created a conversation:', result);

      const userData = await API.graphql(graphqlOperation(getUser, {
        id: this.currentUser.attributes.sub
      }))
      const userName = userData.data.getUser.name

      console.log('userName', userName)

      axios.post(this.props.endpoint + '/start-session', {
        "character_name": this.props.selectedCelebrity.name,
        "user_name": userName
      })
      .then(response => {
        console.log(response); // Adjust this based on your actual API response
      })
      .catch(error => {
        console.error(error);
      });

    } catch (error) {
      console.log('Error creating new conversation:', error);
    }
  };

  startSession = () => {

  }

  handleClose = () => {
    this.setState({ dialogOpen: false });
  }

  handlePlay = (e) => {
    if (this.state.voiceMessageLimitReached) {
      this.setState({ dialogOpen: true });

      setTimeout(() => { 
        e.target.pause();
        e.target.currentTime = 0; // Optional. Resets the audio playback to the start
    }, 10);
    }
  }

  createNewMessage = async (text) => {
    try {
      //const currentUser = await Auth.currentAuthenticatedUser();
  
      const response = await API.graphql(graphqlOperation(listConversations));
      const allConversations = response.data.listConversations.items;
  
      const userAndCelebrity = `${this.currentUser.attributes.sub}_${this.props.selectedCelebrity.id}`;
  
      // Filter the required conversation 
      //const currentConversation = allConversations.find(conversation => conversation.userAndCelebrity === userAndCelebrity);
  
      if (this.currentConversation) {
        const result = await API.graphql(graphqlOperation(createMessage, {
          input: {
            text: text,
            isBotResponse: false,
            isVoiceMessage: false,
            timestamp: new Date().toISOString(),
            conversationId: this.currentConversation.id, // Work with the ID of the conversation
          }
        }));

        console.log('Created a message:', result);

        this.scrollToBottom()

        mixpanel.track('Message Sent', {
          text: text,  // Example property
          recipient: this.props.selectedCelebrity.name,  // Example property
        });

        /*axios.post(this.props.endpoint + '/run', { 
          'user_input': text, 
          'character_name': this.props.selectedCelebrity.name, 
          'user_id': this.currentUser.attributes.sub, 
          'conversation_id': this.currentConversation.id, 
          'voice_id': this.props.selectedCelebrity.voiceId 
        })
        .then(async response => {   // Added `async` here
          let newConversation = this.state.conversation;
          if (response.data.type === 'audio') {
            const audioBase64 = response.data.data;
            const audioUrl = `data:audio/mpeg;base64,${audioBase64}`;
            newConversation = [...newConversation, { 'audioUrl': audioUrl, 'isBotResponse': true, 'isVoiceMessage': true }];
            API.graphql(graphqlOperation(createMessage, {
              input: {
                text: audioUrl,
                isBotResponse: true,
                isVoiceMessage: true,
                timestamp: new Date().toISOString(),
                conversationId: this.currentConversation.id,
              }
            })).then(async result2 => {
              this.updateMessageCount(true);
              console.log(result2);
            })
          } else if (response.data.type === 'text') {
            newConversation = [...newConversation, { 'text': response.data.data, 'isBotResponse': true, 'isVoiceMessage': false }];
            console.log(newConversation)
            const result2 = await API.graphql(graphqlOperation(createMessage, {   //Await should be inside async function
              input: {
                  text: response.data.data,
                  isBotResponse: true,
                  isVoiceMessage: false,
                  timestamp: new Date().toISOString(),
                  conversationId: this.currentConversation.id, // Work with the ID of the conversation
              }
            }));

            this.updateMessageCount(false)

            console.log(result2)

          }
        
          this.setState(prevState => ({
            conversation: newConversation,
            loading: false,
            showAlert: false
          }));
        
          //this.props.onChatUpdate(newConversation);
          
        }).catch(error => {
          console.error(`Error: ${error}`);
          this.setState({ loading: false });
        });*/
        axios.post(this.props.endpoint + '/run', {
          'user_input': text,
          'character_name': this.props.selectedCelebrity.name,
          'user_id': this.currentUser.attributes.sub,
          'conversation_id': this.currentConversation.id,
          'voice_id': this.props.selectedCelebrity.voiceId
      }).then(async response => {
          const taskId = response.data.task_id; // Retrieve the task ID from the response
      
          const checkTaskStatus = async () => {
              try {
                  const result = await axios.get(`${this.props.endpoint}/check-task-status/${taskId}`); // Endpoint to check task status
      
                  if (result.data.task_status === 'SUCCESS') {
                      const taskResult = await axios.get(`${this.props.endpoint}/get-task-result/${taskId}`); // Endpoint to get task result
      
                      let newConversation = this.state.conversation;
                      if (taskResult.data.type === 'audio') {
                          const audioBase64 = taskResult.data.data;
                          const audioUrl = `data:audio/mpeg;base64,${audioBase64}`;
                          newConversation = [...newConversation, { 'audioUrl': audioUrl, 'isBotResponse': true, 'isVoiceMessage': true }];
      
                          await API.graphql(graphqlOperation(createMessage, {
                              input: {
                                  text: audioUrl,
                                  isBotResponse: true,
                                  isVoiceMessage: true,
                                  timestamp: new Date().toISOString(),
                                  conversationId: this.currentConversation.id,
                              }
                          }));
                          this.updateMessageCount(true);
                          this.scrollToBottom();
                      } else if (taskResult.data.type === 'text') {
                          newConversation = [...newConversation, { 'text': taskResult.data.data, 'isBotResponse': true, 'isVoiceMessage': false }];
      
                          await API.graphql(graphqlOperation(createMessage, {
                              input: {
                                  text: taskResult.data.data,
                                  isBotResponse: true,
                                  isVoiceMessage: false,
                                  timestamp: new Date().toISOString(),
                                  conversationId: this.currentConversation.id,
                              }
                          }));
                          this.updateMessageCount(false);
                          this.scrollToBottom();
                      }
      
                      this.setState({
                          conversation: newConversation,
                          loading: false,
                          showAlert: false
                      });
                  } else if (result.data.task_status === 'PENDING') {
                      setTimeout(checkTaskStatus, 2000); // Check again after 2 seconds
                  } else {
                      // Handle other task statuses accordingly
                      console.error('Task failed or encountered an error');
                  }
              } catch (error) {
                  console.error('Error checking task status or getting task result:', error);
                  this.setState({ loading: false });
              }
          };
      
          // Initiate the task status check
          checkTaskStatus();
      }).catch(error => {
          console.error(`Error: ${error}`);
          this.setState({ loading: false });
      });      
      } else {
        console.error('No conversation found with the given user and celebrity');
      }
    } catch (error) {
      console.log('Error creating new message:', error);
    }
  };

  fetchStripePricing = async (productId) => {
    try {
      const response = await axios.post(`${this.props.endpoint}/get-stripe-pricing`, {
        'product_id': productId,
      });

      console.log(response)

      const pricingData = response.data.price;

      console.log(pricingData)
      this.setState({ pricingData });
    } catch (error) {
      console.error('Error fetching Stripe pricing:', error.message);
      // Handle the error
    }
  };
  

  render() {
    const { isLoading } = this.state;

    if (isLoading) {
      return <div>Loading...</div>; // Or a loading spinner while initializing Stripe
    }
    //let imageRatio = 480 / 640; // width / height
    //let vh = window.innerHeight * 0.01;
    //let vw = window.innerWidth * 0.01;
    //let elementHeight = vh * 100; // chat component wants to take up 100vh
    //let elementWidth = elementHeight * imageRatio;
    // "https://pbxt.replicate.delivery/ZXIfokdzxEWiQyiReaBRqT8tmTF4FxkNd8cu2PejBHNVeMMFB/out-0.png"
    // "https://i.postimg.cc/wM3wQqCS/Pers-04-p10.jpg"
    let backgroundImageUrl = "https://pbxt.replicate.delivery/ZXIfokdzxEWiQyiReaBRqT8tmTF4FxkNd8cu2PejBHNVeMMFB/out-0.png"
    const imageRatio = 480 / 640;  // width / height, adjust according to your image's ratio

    let vw = window.innerWidth * 0.01;
    let vh = window.innerHeight * 0.01;
    
    let viewportHeight = `calc(var(--vh, ${vh}px) * 100)`;
    let viewportWidth = `${vw * 100}px`;
    
    let elementHeight = '100%'
    let elementWidth = `min(calc(${viewportHeight} * ${imageRatio}), ${viewportWidth})`;

    if (elementWidth > window.innerWidth) {
      elementHeight = vh * 100; // chat component wants to take up 100vh
      elementWidth = vw * 100
      console.log(window.innerWidth)
      console.log(elementWidth)
    }
        
    const aspectRatio = 640 / 480;  // height / width -> adjust according to your image's ratio

    const celebrities = ['Mindy', 'Bob', 'Alice'];
    
    let style = {
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        padding: '0%',
        height: elementHeight,
        width: elementWidth,
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        backgroundColor: 'white',
        overflow: 'hidden',
        paddingTop: '115px'
    }
      
    return (
      <>
      <AppBar position="absolute" style={{ top: '55px', left: '50%', transform: "translateX(-50%)", boxShadow: '0px 3px 3px 0px rgba(0, 0, 0, 0.2)', width: `${elementWidth}`, backgroundColor: 'white'}}>
        <Toolbar>
          <IconButton edge="start" color="black" aria-label="back" onClick={() => !this.state.loading && this.props.onChatClose()} style={{ opacity: this.state.loading ? 0.5 : 1, pointerEvents: this.state.loading ? 'none' : 'auto' }}>
            <ArrowBackIcon />
          </IconButton>
          <img src={this.props.selectedCelebrity.image} style={{ borderRadius: "50%", objectFit: 'cover', height: '45px', width: '45px', marginRight: '7px'}} />
          <Typography variant="h6" color="black">
            {this.props.selectedCelebrity.name}
          </Typography>
        </Toolbar>
      </AppBar>
      {this.state.showAlert && <Alert severity="warning" style={{ position: 'absolute', top: 0, left: 0, right: 0, margin: 'auto', zIndex: 2, maxWidth: elementWidth }}>Your character is loading. <strong>This may take a while.</strong></Alert>}
      
      <Dialog open={this.state.validSubscription === false}>
      <Avatar
        alt="Celebrity Image"
        src={this.props.selectedCelebrity.image} // Replace with the actual image URL
        sx={{
          width: 80, // Adjust the size of the circular image
          height: 80,
          margin: '0 auto', // Center the image horizontally
          marginTop: 2
        }}
      />
      <DialogTitle sx={{ textAlign: 'center' }}>
        <Typography variant="h4">
          Subscribe to {this.props.selectedCelebrity.name}
        </Typography>
      </DialogTitle>
      <DialogContent>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
        <Typography variant="h5" align='center'>🎤 Unlimited voice messages</Typography>
        <Typography variant="h5" align='center'>💬 Unlimited messages</Typography>
        <Typography variant="h5" align='center'>🤖 Upgraded AI model</Typography>
        <Typography variant="h5" align='center'>✅ Unrestricted access</Typography>
        <Typography variant="h6" align="center">
          {this.state.pricingData ? (
            <span style={{ color: "black", fontWeight: "bold" }}>
              {`$${this.state.pricingData}/2 days`}
            </span>
          ) : (
            "Loading..."
          )}
        </Typography>
      </Box>
      </DialogContent>
      <DialogActions>
      <Button
        fullWidth
        onClick={this.initiateStripeCheckout}
        variant="contained"
        className="pulse-button" // Apply the 'pulse-button' class
        style={{ backgroundColor: "#A873E8", color: "#FFF" }}
      >
        Subscribe Now
      </Button>
      </DialogActions>
      </Dialog>

      {/*
      <Dialog open={this.state.messageLimitReached}>
      <DialogTitle align='center'>You Have Reached Your Message Limit</DialogTitle>
      <DialogContent>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
        <Typography variant="h4" fontWeight='600' align='center'>Upgrade to Ploki Pro for:</Typography>
        <Typography variant="h5" align='center'>🎤 Unlimited voice messages</Typography>
        <Typography variant="h5" align='center'>💬 Unlimited messages</Typography>
        <Typography variant="h5" align='center'>🤖 Upgraded AI model</Typography>
        <Typography variant="h5" align='center'>✅ Unrestricted access</Typography>
        <Typography variant="h3" align="center">
          <span style={{ color: "red", fontWeight: "bold" }}>$0.99</span>{" "}
          <span style={{ textDecoration: "line-through" }}>$6.99</span>
        </Typography>
        <Typography variant="caption" align='center'>
          for the first week. $6.99/week after
        </Typography>
      </Box>
      </DialogContent>
      <DialogActions>
      <Button
        fullWidth
        onClick={this.initiateStripeCheckout}
        variant="contained"
        className="pulse-button" // Apply the 'pulse-button' class
        style={{ backgroundColor: "#A873E8", color: "#FFF" }}
      >
        Upgrade Now
      </Button>
      </DialogActions>
      </Dialog>

      <Dialog open={this.state.dialogOpen} onClose={this.handleClose} PaperProps={{style: { overflowX: 'hidden' }}}>
      <DialogActions>
        <IconButton edge="end" color="inherit" onClick={this.handleClose} aria-label="close">
          <CloseIcon />
        </IconButton>
      </DialogActions>
      <DialogTitle align='center'>You Have Reached Your Free Voice Message Limit</DialogTitle>
      <DialogContent>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
        <Typography variant="h3" fontWeight='600' align='center'>Upgrade to Ploki Pro for:</Typography>
        <Typography variant="h5" align='center'>🎤 Unlimited voice messages</Typography>
        <Typography variant="h5" align='center'>💬 Unlimited messages</Typography>
        <Typography variant="h5" align='center'>🤖 Upgraded AI model</Typography>
        <Typography variant="h5" align='center'>✅ Unrestricted access</Typography>
        <Typography variant="h3" align="center">
          <span style={{ color: "red", fontWeight: "bold" }}>$0.99</span>{" "}
          <span style={{ textDecoration: "line-through" }}>$6.99</span>
        </Typography>
        <Typography variant="caption" align='center'>
          for the first week. $6.99/week after
        </Typography>
      </Box>
      </DialogContent>
      <DialogActions>
      <Button
        fullWidth
        onClick={this.initiateStripeCheckout}
        variant="contained"
        className="pulse-button" // Apply the 'pulse-button' class
        style={{ backgroundColor: "#A873E8", color: "#FFF" }}
      >
        Upgrade Now
      </Button>
      </DialogActions>
      </Dialog>
      */}

      <Paper square id='chat-box' style={style} elevation={0}>
      <Grid container style={{ overflow: 'auto', flex: 0.9, marginTop: '20px' }}>
        <div style={{ overflowY: 'auto', minWidth: '100%' }}>
          {this.state.conversation.map((message, index) =>
              <div key={index} style={{ textAlign: message.isBotResponse ? 'left' : 'right', paddingRight: '20px', paddingLeft: '20px' }} ref={index === (this.state.conversation.length - 1) ? this.messagesEndRef : null}>
                  {(!message.isVoiceMessage && !message.text.startsWith('data:audio/mpeg')) &&
                          <div style={{
                          backgroundColor: message.isBotResponse ? '#bbdefb' : '#ffcdd2',
                          color: 'black',
                          borderRadius: '20px',
                          padding: '10px',
                          marginBottom: '10px',
                          display: 'inline-block',
                          maxWidth: '70%',
                          opacity: '75%'
                          }}>
                          {message.text}
                          </div>
                  }
                  {
                      (message.isVoiceMessage || (message.text && message.text.startsWith('data:audio/mpeg')))
                      ? <audio controls onPlay={(e) => this.handlePlay(e)}>
                            <source src={message.isVoiceMessage ? message.audioUrl : message.text} type="audio/mpeg" />
                            Your browser does not support the audio tag.
                        </audio>
                      : null
                  }
              </div>
          )}
          { this.state.loading && <TypingIndicator /> }
          <div ref={this.messagesEndRef} />
        </div>
      </Grid>
          
      <form onSubmit={this.submitMessage} style={{position: 'sticky', bottom: 0, padding: 10, background: '#fff'}}>
        <TextField 
            inputRef={this.inputRef}
            fullWidth
            variant="filled"
            label="Message"
            value={this.state.userMessage}
            onChange={this.handleChange}
            disabled={this.state.loading || this.messageLimitReached}
            InputProps={{
                disableUnderline: true,
                endAdornment: (
                    <IconButton color="default" type="submit">
                        <SendIcon style={{color: 'lightgray'}}/>
                    </IconButton>
                ),
                style: {
                    color: 'white',
                    borderRadius: '20px',
                    backdropFilter: 'blur(10px)',
                    backgroundColor: 'rgba(0, 0, 0, 0.4)',
                    minWidth: '100%'
                },
            }}
            InputLabelProps={{
                style: {
                    color: 'lightgray'
                }
            }}
            style={{
                borderRadius: '20px',
                marginBottom: '0px'
            }}
        />
      </form>
      </Paper>
      </>
    );
  }
}

export default Chat;