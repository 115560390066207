import { Auth, API, graphqlOperation } from 'aws-amplify';
import { Paper, Button, Box, Typography } from '@mui/material';
import { useEffect, useState } from 'react';

import { getUser } from '../graphql/queries';

import mixpanel from 'mixpanel-browser';

function Settings({ user }) {

    const [userData, setUserData] = useState(null);
    const [isPaidUser, setIsPaidUser] = useState(null);
    const [subscriptions, setSubscriptions] = useState(null);

    const signOut = async () => {
        try {
        await Auth.signOut();
        } catch (error) {
        console.log('error signing out: ', error);
        }
    };

    const checkUser = async () => {
        try {
            const user = await Auth.currentAuthenticatedUser();
            console.log(user)
            setUserData(user);
            const newUserData = await API.graphql(graphqlOperation(getUser, {
                id: user.attributes.sub
            }))

            console.log(newUserData.data)
            setIsPaidUser(newUserData.data.getUser.isPaidUser)
            setSubscriptions(newUserData.data.getUser.subscriptions)
        } catch (error) {
            console.log(error)
        }
    }

    const sendEmail = () => {
        const email = 'help@ploki.fun';
        const subject = 'Subject of your email';
        const body = 'Body of your email';
      
        const mailtoLink = `mailto:${email}?subject=${subject}&body=${body}`;
      
        // Open the default email client
        window.open(mailtoLink);
      };

    useEffect(() => {
        checkUser();
        console.log(isPaidUser)
        mixpanel.track('Page Viewed', { page: 'Settings' });
    }, []);

    const imageRatio = 480 / 640;  // width / height, adjust according to your image's ratio

    let vw = window.innerWidth * 0.01;
    let vh = window.innerHeight * 0.01;
    
    let viewportHeight = `calc(var(--vh, ${vh}px) * 100)`;
    let viewportWidth = `${vw * 100}px`;
    
    let elementHeight = viewportHeight;
    let elementWidth = `min(calc(${viewportHeight} * ${imageRatio}), ${viewportWidth})`;

    if (elementWidth > window.innerWidth) {
      elementHeight = vh * 100; // chat component wants to take up 100vh
      elementWidth = vw * 100
    }

    let style = {
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        padding: '0%',
        height: elementHeight,
        width: elementWidth,
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        backgroundColor: 'white',
        overflow: 'hidden',
        paddingTop: '70px'
    }

    let boxStyle = {
        display: 'flex', 
        flexDirection: 'row', 
        justifyContent: 'space-between', 
        padding: '20px',
        alignItems: 'center',
        boxShadow: '0px 3px 5px 0px rgba(0, 0, 0, 0.2)',
        border: 'solid',
        borderRadius: '5px',
        borderWidth: '1px',
        borderColor: 'gray',
        marginLeft: '10px',
        marginRight: '10px',
        marginBottom: '15px'
    }

    return (
        <Paper square id='home-box' style={style} elevation={0}>
            <Box sx={(theme) => ({ marginLeft: '-10px', marginRight: '-10px', [theme.breakpoints.down('sm')]: {marginLeft: theme.spacing(1), marginRight: theme.spacing(1) },})}>
                <Typography variant="h5" marginLeft='10px' marginBottom='20px' fontWeight='600' gutterBottom>Settings</Typography>
                <Box style={boxStyle}>
                    <Typography variant="p" fontWeight='500' gutterBottom>Current Subscriptions:</Typography>
                    <Typography variant="p" fontWeight='500' gutterBottom>{subscriptions !== undefined ? subscriptions : 'Data Unavailable'}</Typography>
                </Box>
                <Box style={boxStyle}>
                    <Typography variant="p" fontWeight='600' gutterBottom>{userData && userData.attributes.email}</Typography>
                    <Button onClick={signOut} type='submit' variant="outlined" style={{ color: 'red', borderColor: 'red' }}>Sign Out</Button>
                </Box>
                <Box style={boxStyle}>
                    <Typography variant="p" fontWeight='600' gutterBottom>Need Help?</Typography>
                    <Button onClick={sendEmail} type='submit' variant="outlined" color='primary'>Contact Support</Button>
                </Box>
            </Box>
        </Paper>
    );
}

export default Settings;