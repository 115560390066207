import React, { useState, useCallback, useEffect } from 'react';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import HomeIcon from '@mui/icons-material/Home';
import ChatIcon from '@mui/icons-material/Chat';
import SettingsIcon from '@mui/icons-material/Settings';
import { Box } from '@mui/system';
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button, Container, Typography } from '@mui/material';
import blue from '@mui/material/colors/blue';
import axios from 'axios';
import { Amplify, API, Auth, graphqlOperation, Hub } from 'aws-amplify';
import { withAuthenticator, Authenticator, useTheme, useAuthenticator, TextField, SelectField } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import awsExports from './aws-exports.js';

import { getUser } from './graphql/queries.js';
import { createUser, updateUser } from './graphql/mutations.js';
import { listCelebrities, listConversations } from './graphql/queries';

import Home from './components/Home'
import Chats from './components/Chats';
import Settings from './components/Settings';

import step1 from './assets/step1.png'
import step2 from './assets/step2.png'
import logo_wide from './assets/logo_wide.png'; 

import mixpanel from 'mixpanel-browser';

import './App.css';

Amplify.configure(awsExports);

function App() {
  const [value, setValue] = useState('home');
  const [messages, setMessages] = useState([]);
  const [user, setUser] = useState(null);
  const [isNewUser, setIsNewUser] = useState(false);
  const [celebrities, setCelebrities] = useState([]);
  const [conversations, setConversations] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [messageLimitReached, setMessageLimitReached] = useState(false);

  const images = [step1, step2];

  const endpoint = "https://ploki-42ba340375a0.herokuapp.com"

  const imageRatio = 480 / 640;
  let vw = window.innerWidth * 0.01;
  let vh = window.innerHeight * 0.01;
  
  let viewportHeight = `calc(var(--vh, ${vh}px) * 100)`;
  let viewportWidth = `${vw * 100}px`;
  
  let elementHeight = viewportHeight;
  let elementWidth = `min(calc(${viewportHeight} * ${imageRatio}), ${viewportWidth})`;

  if (elementWidth > window.innerWidth) {
    elementHeight = vh * 100; // chat component wants to take up 100vh
    elementWidth = vw * 100
  }

  /*
  useEffect(() => {
    axios.post(endpoint + '/session-memory', {
      "character_name": "Mindy"
    })
    .then(response => {
      setMessages(response.data.messages); // Adjust this based on your actual API response
    })
    .catch(error => {
      console.error(error);
    });
  }, []);
  */

  const handleChatUpdate = (newMessages) => {
    setMessages(newMessages);
  };

  const handleClose = async () => {
    try {
      const userData = await Auth.currentAuthenticatedUser();
      const updateNewUser = await API.graphql(graphqlOperation(updateUser, {
        input: {
          id: userData.attributes.sub,
          isNewUser: false
        }
      }))
      console.log(updateNewUser)
    } catch (error) {
      console.log(error)
    }
    setIsNewUser(false);
    setCurrentIndex(0); // Reset index when dialog is closed
  };

  const handleNext = () => {
    if (currentIndex < (images.length - 1)) {
      setCurrentIndex(prevIndex => prevIndex + 1);
    } else {
      handleClose();
    }
  };
  
  const buttonText = currentIndex === (images.length - 1) ? 'Done' : 'Next';

  const checkUser = async () => {
    try {
      const userData = await Auth.currentAuthenticatedUser();
      const userInDB = await API.graphql({
        query: getUser, 
        variables: { id: userData.attributes.sub },
        authMode: 'AMAZON_COGNITO_USER_POOLS'  
      });
  
      // User doesn't exist in database, let's create it.
      if (!userInDB.data.getUser) {
        const newUser = await API.graphql(graphqlOperation(createUser, { 
          input: { 
            id: userData.attributes.sub,   // Use the unique identifier provided by Cognito
            name: userData.attributes.name,
            gender: userData.attributes.gender,
            email: userData.attributes.email, 
            messageCount: 0,
            voiceMessageCount: 0,
            isNewUser: true,
            isPaidUser: false
          },
          authMode: 'AMAZON_COGNITO_USER_POOLS'
        }));
        mixpanel.track('Sign Up', {
            distinct_id: userData.attributes.sub,
            name: userData.attributes.name,
            email: userData.attributes.email,
            isPaidUser: false
            // Add any other relevant properties
        });
        console.log('User data: ', userData.attributes);
        console.log('User created: ', newUser);
        //setValue('character'); 
        setIsNewUser(true); 
      } 
      // User exists and it's their first time, show character creation screen.
      else if (userInDB.data.getUser.isNewUser) {
        console.log('Existing new user: ', userInDB.data.getUser);
        //setValue('character');
        setIsNewUser(true);
      }
      // Existing user and not a new user 
      else {
        console.log('Existing user: ', userInDB.data.getUser);
      }
  
      setUser(userData);
    } catch (error) {
      console.log('Error: ', error);
      if (error.errors) {
        error.errors.forEach(error => console.log(error))
      }
    }
  };

  const fetchCelebrities = async () => {
    try {
      const celebrityData = await API.graphql(graphqlOperation(listCelebrities));
      const celebrities = celebrityData.data.listCelebrities.items;

      const sortedCelebrities = [...celebrities].sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));

      setCelebrities(sortedCelebrities);    
    } catch (error) {
      console.log("Error on fetching celebrities", error);
    }
  };

  useEffect(() => {
    checkUser();
  }, []);

  useEffect(() => {
    const listener = (data) => {
      // change state or do something when user signs up or signs in
      switch (data.payload.event) {
        case 'signIn':
        case 'signUp':
          console.log('user signed in'); // or signed up
          checkUser();
          fetchCelebrities();
          break;
        case 'signOut':
          console.log('user signed out');
          break;
        default:
          break;
      }
    };

    Hub.listen('auth', listener);

    return () => Hub.remove('auth', listener); // unsubscribe on cleanup to avoid memory leaks
  }, []);

  useEffect(() => {
    fetchCelebrities();
    mixpanel.track('Page Viewed', { page: 'App.js' });
  }, []);
 
    return (
      <Authenticator initialState="signUp" variation="modal" onStateChange={authState => console.log(authState)} components={{
        SignUp: {
          FormFields() {
            const { validationErrors } = useAuthenticator();

            return (
              <>
                <TextField
                  isRequired="true"
                  errorMessage={validationErrors.name}
                  hasError={!!validationErrors.name}
                  key="name"
                  name="name"
                  label="Name"
                  placeholder="Please enter you Name"
                 />

                <SelectField
                  isRequired="true"
                  errorMessage={validationErrors.name}
                  hasError={!!validationErrors.name}
                  key="gender"
                  name="gender"
                  label="Gender"
                  placeholder="Please select your Gender"
                >
                  <option value="male">Male</option>
                  <option value="female">Female</option>
                </SelectField>

                <Authenticator.SignUp.FormFields />
              </>
            );
          },
        },
      }}
      >
      <Box 
        sx={{ 
          position: 'relative',  // add relative position here
          minHeight: '100vh', //takes full height of viewport
          backgroundColor: 'white'
        }}
      >
        <Box style={{ }}>
        <Dialog open={isNewUser} onClose={handleClose}>
        <DialogTitle align='center'>Add Ploki To Your Home Page For A Better Experience!</DialogTitle>
        <DialogContent>
          <img src={images[currentIndex]} alt="Instruction" style={{width: '100%'}} />
        </DialogContent>
        <DialogActions>
        <Button fullWidth onClick={handleNext} variant="contained" style={{backgroundColor: "#A873E8", color: "#FFF"}}>
            {buttonText}
          </Button>
        </DialogActions>
        </Dialog>
        {value === 'home' && <Home celebrities={celebrities} endpoint={endpoint} setMessageLimitReached={setMessageLimitReached}/>}
        {value === 'chats' && <Chats conversations={conversations} messages={messages} endpoint={endpoint} onChatUpdate={handleChatUpdate} />}
        {value === 'settings' && <Settings />}
        </Box>
        <BottomNavigation
            showLabels
            value={value}
            onChange={(event, newValue) => {
              // On change of tab
              if (newValue !== value) {
                // If changing to chat tab
                if (newValue === "chats") {
                  //fetchSessionData();
                }
                // update the tab
                setValue(newValue);
              }
            }}
            sx={{
              height: '55px',
              width: elementWidth,
              position: "absolute",
              top: 0,
              left: '50%',
              transform: "translateX(-50%)",
              backgroundColor: "#A873E8"
            }}
          >
          <BottomNavigationAction label="Home" value="home" icon={<HomeIcon />} style={{ color: 'white', opacity: value === 'home' ? '100%' : '70%' }}/>
          {/*<BottomNavigationAction label="Chats" value="chats" icon={<ChatIcon />} style={{ color: 'white', opacity: value === 'chats' ? '100%' : '70%' }}/>*/}
          <BottomNavigationAction label="Settings" value="settings" icon={<SettingsIcon />} style={{ color: 'white', opacity: value === 'settings' ? '100%' : '70%' }}/>
        </BottomNavigation>
        <script>
            {`
            window.addEventListener("load", function() {
              setTimeout(() => {
                window.scrollTo(0, 1);
              }, 0);
            });
            `}
        </script>
      </Box>
      </Authenticator>
    );
  }


  export default App;